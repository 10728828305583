<template>
    <div id="about-subpage">
        <div class="img">
            <img :src="require('@/assets/images/about/subpage_banner.png')" alt="">
            <img :src="require('@/assets/images/about/about-bg_01.png')" class="bg-bottom" alt="">
        </div>
        <div class="subpage-navs">
            <div class="breadcrumbs">
                <el-breadcrumb>
                    <el-breadcrumb-item v-for="(item, index) in breadcrumbs" :key="index" :to="item.href">
                        {{ item.text }}
                    </el-breadcrumb-item>
                </el-breadcrumb>
            </div>
        </div>
        <div class="content">
<!--            <div class="table-list" v-if="dataList && dataList.length > 0">-->
            <div class="table-list">
                <div class="lf">
                    <img :src="require('@/assets/images/about/windmill.png')" alt="">
                </div>
                <div class="rf">
                    <div class="list">
                        <ComponentCardListDownloadH5 @popDownload = "checkRecourseHandle" :dataList="dataList" dateVar = "createTime"/>
                        <el-table
                                :data="dataList"
                                style="width: 100%">
                            <el-table-column
                                :prop="isEn ? 'enTitle' : 'cnTitle'"
                                label="文件名称">
                                <template slot-scope="scope">
                                    <span style="margin-left: 10px;">{{ isEn ? scope.row.enTitle : scope.row.cnTitle }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="name"
                                :label="isEn ? 'updateTime' : '发布时间'"
                                width="180">
                                <template slot-scope="scope">
                                    {{ scope.row.createTime | formatDate }}
                                </template>
                            </el-table-column>
                            <el-table-column
                                width="140"
                                :label="isEn ? 'operate' : '操作'">
                                <template slot-scope="scope">
                                    <span style="cursor: pointer;" @click="checkRecourseHandle(scope.row.id)">查看</span>
                                </template>
                            </el-table-column>
                        </el-table>
                        <div class="pagination">
                            <el-pagination
                                background
                                layout="prev, pager, next"
                                @current-change="getSubpageList"
                                :current-page.sync="pageCurrent"
                                :page-size="pageSize"
                                :total="total">
                            </el-pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <el-dialog
                title="资源下载"
                :visible.sync="dialogTableVisible"
                :close-on-click-modal="false">
            <el-table :data="recourseList.files">
                <el-table-column
                    :prop="isEn ? 'fileEnName' : 'fileCnName'"
                    :label="isEn ? 'name' : '名称'">
                </el-table-column>
                <el-table-column
                    :label="isEn ? 'qrcode' : '手机查看二维码'"
                    width="120"
                    align="center">
                    <template slot-scope="scope">
                        <vue-qr :text="scope.row.fileExternalUrl" :margin="0" colorDark="#000" colorLight="#fff" :size="64" v-if="scope.row.fileExternalUrl"></vue-qr>
                    </template>
                </el-table-column>
                <el-table-column
                    :label="isEn ? 'operate' : '操作'"
                    width="120"
                    align="center">
                    <template slot-scope="scope">
                        <span style="cursor: pointer;" @click="recourseDownloadHandle(scope.row)">下载</span>
<!--                        <a :href="staticPath + scope.row.fileUrl" target="_blank" :download="isEn ? scope.row.fileEnName : scope.row.fileCnName">下载</a>-->
                    </template>
                </el-table-column>
            </el-table>
        </el-dialog>
    </div>
</template>
<script>
    import {urls, staticPath} from '@/lib/common'
    import ComponentCardListDownloadH5 from '@/components/ComponentCardListDownloadH5'
    import vueQr from 'vue-qr'
    
    export default {
        name: "AboutSubpage",
        components: {
            ComponentCardListDownloadH5,
            vueQr
        },
        data() {
            return {
                staticPath,
                type: 0,
                page: 1,
                pageCurrent: 1,
                pageSize: 10,
                total: 0,
                breadcrumbs: [
                    {
                        text: "home",
                        pathName: 'Home',
                        disabled: false,
                        href: '/'
                    }, {
                        text: "product",
                        pathName: 'Product',
                        disabled: false,
                        href: '/c/product/NTk%3D'
                    }, {
                        text: "recourseDownload",
                        pathName: 'RecourseDownload',
                        disabled: true,
                        href: ''
                    }
                ],
                isEn: false,
                navId: '',
                dataList: [],
                dialogTableVisible: false,
                recourseList: [],
            };
        },
        watch: { // watch 中不能用箭头函数，不然this指向出问题
            "$i18n.locale": function(val) {
                this.isEn = val === 'en' ? true : false;
            }
        },
        methods: {
            getBreadcrumbs() { // 获取面包屑导航列表
                this.$http.get(urls.getBreadcrumb + this.navigationId).then(res => {
                    if (res.data.status === 200) {
                        const isEn = this.$i18n.locale === 'en' ? true : false;
                        const result = res.data.data;
                        const homePage = {
                            text: isEn ? 'Home' : '首页',
                            pathName: 'Home',
                            disabled: false,
                            href: '/'
                        }
                        this.breadcrumbs = [
                            homePage,
                            {
                                text: isEn ? result[0].enName : result[0].cnName,
                                pathName: result[0].templateUrl,
                                disabled: false,
                                href: result[0].templateUrl
                            }, {
                                text: isEn ? result[1].enName : result[1].cnName,
                                pathName: result[1].templateUrl,
                                disabled: true,
                                href: ''
                            }
                        ]
                    } else if (res.data.status !== 401 && res.data.status !== 106) {
                        this.$message.warning(res.data.message)
                    }
                })
            },
            getSubpageList() {
                const params = {
                    pageSize: this.pageSize,
                    pageCurrent: this.pageCurrent
                }
                params.navigationId = this.navId;
                this.$http.get(urls.getSubpageList, {params}).then(res => {
                    if (res.data.status === 200) {
                        this.dataList = res.data.data.records;
                        this.total = res.data.data.total;
                    } else if (res.data.status !== 401 && res.data.status !== 106) {
                        this.$message.error(res.data.message)
                    }
                })
            },
            checkRecourseHandle(id) {
                this.$http.get(urls.getArticleDetail + id).then(res =>{
                    if (res.data.status === 200) {
                        this.recourseList = res.data.data;
                        this.dialogTableVisible = true;
                    } else if (res.data.status !== 401 && res.data.status !== 106) {
                        this.$message.error(res.data.message)
                    }
                })
            },
            recourseDownloadHandle(row) {
                const self = this;
                let msg = self.$message.warning({
                    showClose: true,
                    duration: 0,
                    message: '正在读取..请勿关闭窗口'
                });

                function judgePort(url,name){
                    var u = navigator.userAgent;
                    if((u.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))) {
                        var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
                        var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
                        // if(isAndroid)
                        //     alert('android');
                        // if(isiOS)
                        //     alert('ios');
                        let a = document.createElement('a');
                        a.download = name;
                        a.href = url;
                        a.target = '_blank';
                        a.click();
                        return;
                    }
                }
                judgePort(self.staticPath + row.fileUrl,self.isEn ? row.fileEnName : row.fileCnName);
                
                var xhr = new XMLHttpRequest();
                xhr.open("GET", self.staticPath + row.fileUrl, true);
                xhr.responseType = 'blob';
                xhr.onload = function(e) {
                    var url = window.URL.createObjectURL(xhr.response)
                    var a = document.createElement('a');
                    a.href = url;
                    a.download = self.isEn ? row.fileEnName : row.fileCnName;
                    a.click()
                    if (xhr.status !== 200 && xhr.readyState === 4) {
                        self.$message.error({
                            duration: 3000,
                            message: '下载失败！'
                        })
                    }
                    msg.close();
                }
                xhr.send();
            }
        },
        created() {
        
        },
        mounted() {
            this.navId = window.atob(decodeURIComponent(this.$route.query.n));
            this.navigationId = window.atob(decodeURIComponent(this.$route.params.navigationId))
            this.getBreadcrumbs();
            this.getSubpageList();
        }
    };
</script>
<style lang="less" scoped>
    @deep: ~'>>>';
    @pointWidth: 13vw;
    #about-subpage {
        >.img {
            position: relative;
            .bg-bottom {
                position:absolute;
                bottom: 0;
                left: 0;
            }
        }
        .subpage-navs {
            margin: 0 auto;
            color: #7C7FA2;
            font-size: 1.25vw;
            width: 100%;
            background: url("../../assets/images/about/about-bg_02.jpg") no-repeat center center;
            background-size: 100% 100%;
            @{deep} .el-breadcrumb{
                font-size: 18px;
                .el-breadcrumb__inner a, 
                .el-breadcrumb__inner.is-link{
                    color:#625F6F;
                    font-weight: normal;
                }
            }
            .breadcrumbs {
                width: 1400px;
                margin:0 auto;
                .link {
                    padding: 0 5px;
                }
            }
            .navs {
                width: 1400px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin: 2vw auto 4vw;
                li {
                    width: 148px;
                    height: 64px;
                    text-align: center;
                    line-height: 64px;
                    position: relative;

                    a{
                        font-size: 18px;
                        color:#7C7FA2;
                    }

                    &:not(:last-child)::after {
                        content: '';
                        display: inline-block;
                        position: absolute;
                        top: 50%;
                        right: -10%;
                        height: 30px;
                        margin-top:-15px;
                        border-right: 1px solid #EAEAEA;
                    }
                    &:last-child {
                        border: none;
                    }
                    &.actived {
                        background: linear-gradient(to bottom, #FF4B7E, #D93C46);
                        border-radius: 6px;
                        box-shadow: 0 0.52vw 1vw rgba(217, 60, 70, 0.8);
                        &::after {
                            border-right-color: #EAEAEA;
                        }
                        a {
                            color: #fff;
                        }
                    }
                }
            }
        }
        .content {
            padding-bottom: 4vw;
            .company-info {
                font-size: 1.25vw;
                width: 1300px;
                margin: 0 auto;
                .info {
                    .lf {
                        float: left;
                        width: 55%;
                        h2 {
                            color: #353968;
                            font-size: 30px;
                        }
                        >p {
                            font-size: 18px;
                            line-height: 50px;
                            color: #343750;
                            margin: 3vw 0;
                        }
                    }
                    .rf {
                        float: right;
                        width: 40%;
                        img {
                            position: relative;
                            top: 2vw;
                            left: 0;
                        }
                    }
                    &::after {
                        content: '';
                        display: block;
                        clear: both;
                    }
                }
                .pics {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 4vw;
                    li {
                        text-align: center;
                        color: #353968;
                        margin-right: 2vw;
                        &:nth-child(3n) {
                            margin-right: 0;
                        }
                        p {
                            margin-top: 16px;
                            font-size: 18px;
                            font-weight: bold;
                        }
                    }
                }
            }
            .honor {
                width: 76vw;
                margin: 2vw auto 0;
                >h2 {
                    color: #fff;
                }
                .honor-list {
                    font-size: 1.458vw;
                    text-align: center;
                    color: #fff;
                    .pic {
                        &::before,
                        &::after {
                            content: '';
                            display: inline-block;
                            width: 2.7vw;
                            height: 6.5vw;
                            position: relative;
                            top: -0.5vw;
                            left: 0.8vw;
                            background: url('../../assets/images/about/icon_wheat_left.png') no-repeat center center;
                            background-size: 100%;
                        }
                        &::after {
                            left: -0.8vw;
                            background-image: url('../../assets/images/about/icon_wheat_right.png');
                        }
                        img {
                            width: 12.5vw;
                            margin: 0 2vw;
                        }
                    }
                    p {
                        width: 14vw;
                        font-size: 18px;
                        margin: 1vw auto 0;
                    }
                    .swiper-container {
                        padding: 4vw 1.85vw !important;
                    }
                    .swiper-slide:nth-child(even) {
                        margin-top: 4vw !important;
                    }
                    .swiper-button-next {
                        width: 1.3vw;
                        height: 2.29vw;
                        background-image: url('../../assets/images/about/icon_arrow_right.png');
                        background-size: 100%;
                    }
                    .swiper-button-prev {
                        width: 1.3vw;
                        height: 2.29vw;
                        background-image: url('../../assets/images/about/icon_arrow_left.png');
                        background-size: 100%;
                    }
                    .swiper-pagination {
                        bottom: 0;
                    }
                }
            }
            .process {
                .process-content {
                    width: 60vw;
                    margin: 4vw auto 0;
                    display: flex;
                    justify-content: flex-start;
                    .lf {
                        width: 30vw;
                        height: 30vw;
                        box-sizing: border-box;
                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }
                    .rf {
                        width: 30vw;
                        height: 30vw;
                        background: #1BB2FF;
                        box-sizing: border-box;
                        padding: 4vw 2vw 4vw 4vw;
                        color: #fff;
                        h1 {
                            font-size: 96px;
                        }
                        .point {
                            display: inline-block;
                            width: 32px;
                            height: 32px;
                            background: #fff;
                            border-radius: 50%;
                            margin: 0.5vw 0;
                        }
                        p {
                            font-size: 18px;
                            line-height: 2.2vw;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 6;
                            -webkit-box-orient: vertical;
                        }
                    }
                }
                .time-line {
                    padding: 2vw 0;
                    overflow-x: scroll;
                    &::-webkit-scrollbar {
                        display: none;
                    }
                    ul {
                        padding-left: @pointWidth;
                        &::after {
                            content: '';
                            display: block;
                            clear: both;
                        }
                        li {
                            float: left;
                            text-align: center;
                            width: @pointWidth;
                            padding-top: 5vw;
                            position: relative;
                            strong {
                                font-size: 48px;
                                color: #8ED9FF;
                                position: absolute;
                                left: 1.5em;
                                top: 0;
                                &.actived {
                                    left: 0.5em;
                                    top: -0.35em;
                                    font-size: 56px;
                                    color: #1BB2FF;
                                }
                            }
                            .time-point {
                                width: 100%;
                                height: 0.2vw;
                                background: #8ED9FF;
                                position: relative;
                                cursor: pointer;
                                &::after {
                                    content: '';
                                    display: inline-block;
                                    width: 2.7vw;
                                    height: 2.7vw;
                                    background: #8ED9FF;
                                    border-radius: 50%;
                                    position: absolute;
                                    left: 50%;
                                    margin-left: -1.35vw;
                                    top: -1.35vw;
                                }
                            }
                            .time-point-actived {
                                &::after {
                                    background: #1BB2FF;
                                }
                            }
                        }
                    }
                }
            }
            .text {
                width: 1300px;
                margin: 0 auto 5%;
                font-size: 16px;    
                line-height: 24px;        
            }
            .college,
            .table-list {
                display: flex;
                font-size: 1.25vw;
                color: #5A5A5A;
                padding-bottom: 5vw;
                >.lf {
                    flex: 1;
                    img {
                        width: 580px;
                    }
                }
                >.rf {
                    flex: 2;
                    .types {
                        margin-bottom: 1vw;
                        button {
                            float: left;
                            font-size: 1.25vw;
                            padding: 0.78vw 2.8125vw;
                            background: #fefefe;
                            color: #5A5A5A;
                            border: 1px solid #dbdbdb;
                            outline: none;
                            margin-right: 1.25vw;
                            transition: all .3s linear;
                            &.actived {
                                color: #fff;
                                background: #02A5E6;
                                border-color: #02A5E6;
                            }
                        }
                        &::after {
                            content: '';
                            display: block;
                            clear: both;
                        }
                    }
                    .btns {
                        button {
                            margin-right: 20px;
                            border: 1px solid #ddd;
                            height: 40px;
                            line-height: 40px;
                            outline: none;
                            color: #5A5A5A;
                            font-size: 18px;
                            transition: all .3s linear;
                            width: 100px;
                            border-radius: 5px;
                            &.actived {
                                background-color: #02A5E6;
                                border-color: #02A5E6;
                                color: #fff;
                            }
                        }
                    }
                    @{deep} .el-table tr{
                        font-size: 14px;
                        height: 50px;
                        .cell{
                            line-height: 30px;
                        }
                    }
                }
                .list {
                    margin-top: 20px;
                    width: 86%;
                    li {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        border-bottom: 1px solid #E8E8E8;
                        padding: 1vw 0;
                        width: 80%;
                        a {
                            color: #5A5A5A;
                        }
                        .right-part {
                            width: 40%;
                            display: flex;
                            justify-content: space-around;
                            align-items: center;
                        }
                        .icon {
                            display: inline-block;
                            width: 1.25vw;
                            height: 1.25vw;
                            background: url("../../assets/images/about/icon_order.png") no-repeat center center;
                            background-size: 100%;
                            margin-right: 0.52vw;
                            position: relative;
                            top: .1vw;
                        }
                        .icon-clock {
                            background-image: url("../../assets/images/about/icon_clock.png");
                        }
                        .icon-views {
                            background-image: url("../../assets/images/about/icon_views.png");
                        }
                    }
                    .pagination {
                        margin: 2vw 0;
                        text-align: right;
                        @{deep} .v-pagination {
                            justify-content: flex-end;
                        }
                        @{deep} .v-pagination__item {
                            width: 1.77vw;
                            height: 1.77vw;
                            font-size: 1.2vw;
                            margin: 0.5vw;
                        }
                        @{deep} .v-pagination__navigation {
                            width: 1.67vw;
                            height: 1.67vw;
                        }
                        @{deep} .v-pagination__item--active {
                            background: #02A5E6;
                            color: #fff;
                        }
                    }
                }
                .empty {
                    text-align: center;
                    margin-top: 10%;
                    img {
                        width: 30%;
                    }
                    p {
                        margin-top: 1vw;
                    }
                }
            }
            .table-list {
                margin: 4vw auto 0;
            }
        }
    }
    .fontFn (@px, @attr: font-size) {
        @rem: (@px / 37.5);
        @{attr}: ~"@{rem}rem";
    }

    @media screen and (max-width: 750px) {
        #about-subpage {
            .subpage-navs{
                width: 100%;
                padding: 4%;
                @{deep} .el-breadcrumb{
                    .fontFn(12);
                }
                .breadcrumbs{
                    width: 100%;
                }
                .navs{
                    margin:0;
                    padding:4% 0;
                    display: block;
                    width: 100%;
                    li{
                        display: inline-block;
                        vertical-align: top;
                        width: 33.3%;
                        .fontFn(30,line-height);
                        .fontFn(30,height);
                        a{
                            .fontFn(12);
                        }
                        &:not(:last-child)::after{
                            content:none;
                        }
                    }
                }
            }

            .content{
                .company-info{
                    width: 100%;
                    padding: 0 4%;
                    .info{
                        .lf{
                            width: 100%;
                            h2{
                                .fontFn(16);
                            }
                            &>p{
                                .fontFn(12);
                                .fontFn(20,line-height);
                            }
                        }
                        .rf{display: none}
                    }
                    .pics{
                        display: block;
                        li{
                            p{
                                .fontFn(12);
                                margin-bottom: 6%;
                            }
                        }
                    }   
                }
                .text{
                    width: 100%;
                    padding: 0 6%;
                    .fontFn(12);
                    .fontFn(20,line-height);
                }
                .honor{
                    width: 100%;
                    padding: 0 6%;
                    .honor-list{
                        .pic{
                            img{
                                width: 50vw;
                            }
                            &::before,
                            &::after{width: 10.8vw;height: 30vw;}
                        } 
                        p{width: 100%;.fontFn(14);}
                        .swiper-container{padding-bottom: 8vw !important;}
                        @{deep} .swiper-pagination-bullet-active{background-color: #666}
                    }
                }

                .process{
                    .time-line{
                        ul{
                            padding-top: 30px;
                            padding-left: 0;
                            li{
                                width: 25vw;
                                strong{
                                    font-size: 20px;
                                    left:40%;
                                    top:-16px;
                                    &.actived{
                                        font-size: 30px;
                                        left:40%;
                                        top:-24px;
                                    }
                                }
                            }
                        }
                    }
                    .process-content{
                        width: 100%;
                        padding: 0 6%;
                        flex-direction: column-reverse;
                        .lf{width: 100%;height: auto}
                        .rf{
                            width: 100%;
                            height: auto;
                            h1{.fontFn(18);}
                            p{.fontFn(14);.fontFn(20,line-height)}
                            .point{
                                .fontFn(8,width);
                                .fontFn(8,height);
                            }
                        }
                    }
                }

                .college,
                .table-list{
                    &>.rf{
                        width: 100%;
                        .btns{
                            button{
                                .fontFn(14);
                                .fontFn(96,width);
                                .fontFn(36,height);
                                .fontFn(36,line-height);
                            }
                        }

                    }
                    .list{
                        width: 100%;
                        .el-table{
                            display: none;
                        }
                    }
                }

                .college{
                    padding: 0 6%;
                    &>.lf{
                        display: none
                    }
                    &>.rf{
                        .btns{
                            button{

                            }
                        }
                    }
                }

                .table-list{
                    padding: 0 6%;
                    &>.lf{
                        display: none
                    }
                    &>.rf{
                        .btns{
                            button{

                            }
                        }
                    }
                    .list{
                        margin-top: 0;
                    }
                }
            }
        }
    }
</style>
<style lang="less">
@deep: ~'>>>';
@media screen and (max-width: 750px) {
    .el-dialog__wrapper{
        .el-dialog{
            width: 96% !important;
        }
    }
}
</style>